import gql from 'graphql-tag';
import { onError } from "apollo-link-error";
import { parse as parseQueryString } from 'query-string';

import SnackbarUtils from "../../src/util/SnackbarUtils";
import { LoginUserFields, refreshUser } from "../../src/services/login";
import client from './apollo'; // circular dependency :(

const shareExpiredError = "The link to the page that you are trying to access has expired. Please either login or request a new link.";
const refreshedHash = "#acl-refreshed";

const RefreshUserMemberships = gql`
  query CurrentUser {
    currentUser {
      ${LoginUserFields}
      siteMemberships {
        id
        role
        site {
          id
        }
      }
      groupMemberships {
        id
        role
        group {
          id
          sites {
            id
          }
        }
      }
    }
  }
`;

function usingShareToken() {
  const params = parseQueryString(document.location.search);
  return !!params["share_token"];
}

function handleNotAuthorized() {
  if (usingShareToken()) {
    SnackbarUtils.error(shareExpiredError);
    return;
  }

  // refresh the current user's access rights in case they've changed.
  // if they have NOT changed then the front-end is displaying an action
  // that the backend's access policies rejects (ie a bug).
  if (window.location.hash !== refreshedHash) {
    client.query({
      query: RefreshUserMemberships,
      fetchPolicy: 'network-only',
    }).then(r => {
      refreshUser(r.data.currentUser)
      console.debug("user access rights refreshed")

      // ensure we don't perform this action a bunch of times on the same page
      window.location.hash = refreshedHash
    })
  }

  SnackbarUtils.error("You aren't authorized to access that.")
}

const link = onError(({ graphQLErrors, networkError, ...rest }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === "not_authorized") {
        handleNotAuthorized();
      }
    });
});

export default link;
