import React from "react";
import { ApolloLink } from 'apollo-link';
import Button from '@material-ui/core/Button';

import i18next from '../../src/i18n/config';
import SnackbarUtils from "../../src/util/SnackbarUtils";

let currentVersion = null;

function t(key) {
  return i18next.t(`apollo-version-tracker.${key}`);
}

function upgradeApp() {
  // just refresh the browser
  window.location.href = window.location.href; // eslint-disable-line no-self-assign
}

export default new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();

    const newVersion = context.response.headers.get("X-React-App-SHA");
    if (currentVersion && newVersion && currentVersion !== newVersion) {
      SnackbarUtils.info(
        t("newVersion"),
        {
          action: key => <Button onClick={upgradeApp} color="inherit" textSizeSmall sizeSmall>{t("refresh")}</Button>,
          persist: true,
        }
      )
    }
    currentVersion = newVersion;

    return response;
  });
});
