// https://github.com/iamhosseindhv/notistack/issues/30#issuecomment-542863653
// Allows use to access the snackbar outside components (ie inside Apollo error handlers)
import { useSnackbar } from 'notistack'
import React from 'react'

const InnerSnackbarUtilsConfigurator = props => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
}

let useSnackbarRef = useSnackbar;
const setUseSnackbarRef = useSnackbarRefProp => {
  useSnackbarRef = useSnackbarRefProp;
}

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
}

export default {
  success(message, options) {
    this.toast(message, { variant: 'success', ...options })
  },
  warning(message, options) {
    this.toast(message, { variant: 'warning', ...options })
  },
  info(message, options) {
    this.toast(message, { variant: 'info', ...options })
  },
  error(message, options) {
    this.toast(message, { variant: 'error', ...options })
  },
  toast(message, { variant, ...rest }) {
    useSnackbarRef.enqueueSnackbar(message, { variant: variant || "default", ...rest })
  }
}
