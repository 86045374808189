import { navigate } from 'gatsby';
import { IntercomAPI } from './Intercom';

export default function trackedNavigate(href, forceReload = false, state = {}) {
  if (window.confirmUnsavedChangesMsg && !window.confirm(window.confirmUnsavedChangesMsg)) {
    return;
  }

  window.confirmUnsavedChangesMsg = null;

  if (forceReload) {
    window.location.href = href;
  } else {
    IntercomAPI('update', { last_request_at: parseInt((new Date()).getTime()/1000) });
    navigate(href, { state });
  }
};
