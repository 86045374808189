// samltest.dubbot.com and beta-ui-samltest.dubbot.com are custom domains
// beta-ui.dubbot.com and beta-ui-app.dubbot.com are not

import BrowserUtil from '../util/BrowserUtil';

const appDomain = new RegExp('^(app.)?dubbot.');
const betaDomain = new RegExp('.+.beta.dubbot.com');
const stagingDomain = new RegExp('.+.staging.dubbot.com');

function isCustomDomain(hostname) {
  return !appDomain.exec(hostname) &&
    !betaDomain.exec(hostname) &&
    !stagingDomain.exec(hostname) &&
    hostname !== 'dev.dubbot.com' &&
    hostname !== 'localhost' &&
    hostname !== 'staging-app.dubbot.com'
}

export function determineCustomDomain(hostname) {
  if (isCustomDomain(hostname)) {
    return hostname;
  }
}

export function determineCurrentCustomDomain() {
  const hostname = BrowserUtil.getHostname();
  return determineCustomDomain(hostname);
}

export function isDomainChangeRequired(account) {
  return account.customDomain?.singleSignOnRequired &&
    account.customDomain.name !== window.location.hostname;
}

export function switchAccountViaDomainChange(account, path) {
  const current = window.location;
  const port = current.port ? `:${current.port}` : '';
  const domain = account.customDomain.name;

  const fullpath = `${current.protocol}//${domain}${port}${path}`;
  console.log(`Switching Account requires a domain change: ${fullpath}`);
  window.location = fullpath;
}
