import { ApolloLink } from 'apollo-link';

const threshold = 5; // seconds

export default new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();

    const startAt = context.requestStartedAt;
    const endAt = new Date();

    if (startAt && endAt) {
      const runtime = (endAt - startAt) / 1000;
      if (runtime > threshold) {
        const uuid = response && response.request && response.request.uuid;
        console.warn(`Request took ${runtime} seconds: ${uuid || "UUID Unknown"}`);
      }
    }

    return response;
  });
});
