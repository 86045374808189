import gql from 'graphql-tag';
import { parse as parseQueryString } from 'query-string';

export function getShareToken() {
  return parseQueryString(document.location.search)["share_token"];
}

export function isSharedUrl() {
  return !!getShareToken();
}

export const SharePageMutation = gql`
  mutation SharePage($accountId: String!, $pageId: String!, $email: String!) {
    updatePage(accountId: $accountId, pageId: $pageId, shares: [{ email: $email }]) {
      page {
        id
        shares {
          id
          email
          url
          errors {
            attribute
            code
          }
        }
      }
    }
  }
`;

export const ResendPageShareMutation = gql`
  mutation ResendPageShare($accountId: String!, $pageId: String!, $email: String!) {
    resendPageShare(accountId: $accountId, pageId: $pageId, email: $email) {
      page {
        id
        shares {
          id
          email
          url
        }
      }
    }
  }
`;

export const ResetPageShareMutation = gql`
  mutation ResetPageShare($accountId: String!, $pageId: String!, $shareResetCount: Int!) {
    updatePage(accountId: $accountId, pageId: $pageId, shareResetCount: $shareResetCount) {
      page {
        id
        shareResetCount
        shareUrl
      }
    }
  }
`;

export const UnsharePageMutation = gql`
  mutation UnsharePage($accountId: String!, $pageId: String!, $id: String!) {
    updatePage(accountId: $accountId, pageId: $pageId, shares: [{ id: $id, destroy: true }]) {
      page {
        id
        shares {
          id
          email
          url
        }
      }
    }
  }
`;