import ApolloClient from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import fetch from 'isomorphic-fetch';

import introspectionQueryResultData from './fragmentTypes.json';
import { getSession } from '../../src/services/auth';
import { getShareToken } from '../../src/services/shares';
import errorLink from './apollo-link-error';
import errorNotifier from './apollo-known-error-notifier';
import versionTracker from './apollo-version-tracker';
import requestRuntimeLogger from './apollo-request-runtime-logger';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });
const httpLink = new HttpLink({ uri: `${process.env.GATSBY_API_URL}/graphql` });

const authLink = setContext((_, { headers }) => {
  // don't overwrite an existing Authorization header
  if (headers && headers.Authorization) {
    return headers;
  }

  const additionalHeaders = {};

  const { accessToken } = getSession();
  if (accessToken) {
    additionalHeaders["Authorization"] = `Bearer ${accessToken}`;
  }

  const shareToken = getShareToken();
  if (shareToken) {
    additionalHeaders["X-ShareToken"] = shareToken;
  }

  return {
    headers: {
      ...headers,
      ...additionalHeaders,
    },
    requestStartedAt: new Date(),
  };
});

export default new ApolloClient({
  cache,
  fetch,
  link: ApolloLink.from([
    authLink,  // inserts Authorization header
    errorLink, // refreshes the accessToken
    errorNotifier, // notifies the user when they hit a known error
    versionTracker, // notifies the user when new version of this React app is available
    requestRuntimeLogger, // outputs slow requests to console.warn
    httpLink,  // runs the query
  ]),
});
