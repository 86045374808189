/* eslint-disable import/prefer-default-export, react/prop-types */

import React from 'react';
import TopLayout from './TopLayout';
import client from './apollo';
import { ApolloProvider } from 'react-apollo';

if (typeof window === 'undefined') {
  global.window = {}
}

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <TopLayout>{element}</TopLayout>
  </ApolloProvider>
);
