import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#0071a0',
      contrastText: '#fff'
    },
    secondary: {
      main: '#b0ecff',
      dark: '#7cdbf9',
      contrastText: '#005478'
    },
    error: {
      main: red.A400,
    },
    background: {
      default: grey[50],
    },
  },
  typography: {
    h1: {
      fontWeight: 300
    },
    h2: {
      fontWeight: 300
    },
    h3: {
      fontWeight: 300
    },
    h4: {
      fontWeight: 300
    },
    h5: {
      fontWeight: 300
    },
    h6: {
      fontWeight: 300
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          scrollPaddingTop: '80px',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        '&[disabled]': {
          opacity: .7,
          pointerEvents: 'none',
        },
      }
    },
    MuiLink: {
      root: {
        '&[disabled]': {
          opacity: .7,
          pointerEvents: 'none',
        },
      }
    }
  }
});

export const withLinksUnderlined = (theme) => {
  theme.overrides.MuiLink = {
    ...theme.overrides.MuiLink,
    ...{
      underlineHover: {
        textDecoration: "underline"
      }
    }
  }
}

export default theme;
