import gql from 'graphql-tag';
import { navigate } from 'gatsby';

import client from './apollo'; // circular dependency :(

import { getSession, logout, updateAccessToken } from '../../src/services/auth';

const RefreshTokenMutation = gql`
  mutation RefreshTokenMutation {
    refreshToken {
      accessToken
    }
  }
`;

export async function refreshAccessToken() {
  const { refreshToken } = getSession();

  const response = await client.mutate({
    mutation: RefreshTokenMutation,
    context: {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    },
  });

  if (response.data && response.data.refreshToken && response.data.refreshToken.accessToken) {
    const accessToken = response.data.refreshToken.accessToken;

    await updateAccessToken(accessToken);
    return accessToken;
  } else {
    forceLogout();
  }
}

export async function forceLogout() {
  await logout(() => navigate('/'));
}
