// Mainly based on:
// https://gist.github.com/alfonmga/9602085094651c03cd2e270da9b2e3f7
// https://github.com/apollographql/apollo-link/issues/646

import { onError } from 'apollo-link-error';
import { Observable } from 'apollo-link';
import * as Sentry from "@sentry/gatsby";

import { refreshAccessToken, forceLogout } from './AutoRefreshToken';
import { getSession } from '../../src/services/auth';

let isFetchingToken = false;
let tokenSubscribers = [];
function subscribeTokenRefresh(cb) {
  tokenSubscribers.push(cb);
}
function onTokenRefreshed(err) {
  tokenSubscribers.map(cb => cb(err));
}

const captureError = (error) => {
  const exception = new Error(error);
  console.error(exception);
  Sentry.captureException(exception)
};

/* eslint-disable no-console, consistent-return */
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError) {
    captureError(`[Network error]: ${networkError}`);
  }

  if (graphQLErrors) {
    const { message, locations, path } = graphQLErrors[0];
    const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;

    if (message === 'invalid_authorization_header') {
      console.warn(errorMessage);
      return new Observable(async observer => {
        observer.error("Log-in expired (auth header invalid)");
        return forceLogout();
      });

    } else if (message === 'expired_token') {
      console.warn(errorMessage);
      return new Observable(async observer => {
        const { rememberMe } = getSession();
        if (!rememberMe) {
          observer.error("Log-in expired (expired_token)");
          return forceLogout();
        }

        try {
          const retryRequest = (newAccessToken) => {
            operation.setContext({
              headers: {
                ...headers,
                Authorization: `Bearer ${newAccessToken}`,
              },
            });

            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            return forward(operation).subscribe(subscriber);
          };

          const { headers } = operation.getContext();

          if (!isFetchingToken) {
            isFetchingToken = true;

            try {
              const newAccessToken = await refreshAccessToken();

              isFetchingToken = false;
              onTokenRefreshed(null);
              tokenSubscribers = [];

              return retryRequest(newAccessToken);
            } catch (e) {
              console.error(e);
              observer.error("Log-in expired (Unable to refresh)");
              onTokenRefreshed(new Error('Unable to refresh access token'));

              tokenSubscribers = [];
              isFetchingToken = false;

              return forceLogout();
            }
          }

          const tokenSubscriber = new Promise((resolve, reject) => {
            subscribeTokenRefresh(errRefreshing => {
              if (errRefreshing) return reject(errRefreshing);

              const { accessToken } = getSession();
              return resolve(retryRequest(accessToken));
            });
          });

          return tokenSubscriber;
        } catch (e) {
          console.error(e);
          observer.error(e);
        }
      });
    } else {
      captureError(errorMessage);
    }
  }
});

export default errorLink;
