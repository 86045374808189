import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import Button from '@material-ui/core/Button';
import theme, { withLinksUnderlined } from '../../src/theme';
import { SnackbarUtilsConfigurator } from "../../src/util/SnackbarUtils"
import { isUserSettingEnabled } from '../../src/services/auth';

export default function TopLayout(props) {
  // add action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
      notistackRef.current.closeSnackbar(key);
  };

  isUserSettingEnabled("underline_all_links") && withLinksUnderlined(theme);

  return (
    <React.Fragment>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>DubBot</title>
        <link href="https://fonts.googleapis.com/css?family=Roboto:400,500,700" rel="stylesheet" />
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          ref={notistackRef}
          action={(key) => (
            <Button color="inherit" size="small" onClick={onClickDismiss(key)}>Dismiss</Button>
          )}>
          <SnackbarUtilsConfigurator/>
          {props.children}
        </SnackbarProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
